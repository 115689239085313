import { Children, PropsWithChildren } from "react";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/pagination";
import { Autoplay, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { twMerge } from "tailwind-merge";

type Props = PropsWithChildren & { className?: string; pagination?: boolean };

export default function Carousel(props: Props) {
  return (
    <Swiper
      className={twMerge(
        "relative w-fit max-w-full text-white",
        props.className,
      )}
      modules={[Autoplay, Pagination]}
      slidesPerView="auto"
      spaceBetween={20}
      autoplay={{ delay: 3000 }}
      pagination={props.pagination === false ? false : { clickable: true }}
      loop
    >
      {["left-0", "right-0"].map((pos) => (
        <div
          key={pos}
          className={twMerge(
            "pointer-events-none absolute inset-y-0 z-10 w-4 bg-gradient-to-r from-current to-transparent",
            pos,
            pos === "left-0" ? "bg-gradient-to-r" : "bg-gradient-to-l",
          )}
        />
      ))}
      {Children.map(props.children, (c, i) => (
        <SwiperSlide key={i} className="!w-fit" children={c} />
      ))}
    </Swiper>
  );
}
